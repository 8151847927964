import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    detail: {},
    leader: {},
    searchWords: computed(() => {
      if (proxy.$Util.isEmpty(state.detail.searchWord)) return []
      return state.detail.searchWord.split(',')
    }),
    price: computed(() => {
      if (proxy.$Util.isEmpty(state.detail.useDt)) return ''
      return state.detail.unlimitedCheckYn === 'N'
        ? proxy.$Util.getRemainDate(state.detail.useDt) *
            state.detail.dailyPrice
        : state.detail.dailyPrice
    }),
    form: {
      partyNo: route.query.partyNo,
      partyAgree: 'N'
    }
  })
  const fnDetail = async () => {
    const res = await proxy.$PartySvc.postPartyDetail(route.query)
    state.detail = res.detail
    state.leader = res.leader
  }

  const fnModalAlertRefundPolicy = () => {
    const payload = {
      component: proxy.$modalAlertNames.REFUND_POLICY,
      data: {
        typeName: 'PARTY'
      },
      callback: () => {}
    }
    fnModalAlert(payload)
  }

  const fnModalAlertSellerProfile = () => {
    const payload = {
      component: proxy.$modalAlertNames.SELLER_PROFILE,
      data: {
        userNm: state.leader.userNm,
        profile: state.leader.profile,
        userLvNm: state.leader.userLvNm,
        sellerLv: state.leader.sellerLv,
        productNo: route.query.partyNo,
        userData: state.userData,
        productType: 'PARTY'
      },
      callback: () => {}
    }
    fnModalAlert(payload)
  }

  const fnPage = async () => {
    if (proxy.$Util.isEmpty(state.userData)) {
      await router.push({ path: '/login' })
    } else {
      if (!isValid()) return
      await router.push({ path: '/party/pay', query: { ...state.form } })
    }
  }

  const fnEdit = async () => {
    await router.push({
      path: '/my-page/created-party/edit',
      query: { partyNo: state.detail.partyNo }
    })
  }

  const isValid = () => {
    if (state.form.partyAgree !== 'Y') {
      alert('파티정보와 주의사항을 숙지하시고 동의해주세요.')
      return false
    }
    return true
  }

  const init = async () => {
    await fnDetail()
    if (state.detail.myPartyYn === 'Y') {
      await router.replace({
        name: 'my-page-created-party-view-index',
        query: { partyNo: state.detail.partyNo }
      })
      return
    }
  }
  init()
  return {
    ...toRefs(state),
    fnPage,
    fnEdit,
    fnModalAlertRefundPolicy,
    fnModalAlertSellerProfile
  }
}
