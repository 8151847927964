<template>
  <main class="main" role="main">
    <div class="page" v-if="!$Util.isEmpty(detail)">
      <div class="inner">
        <div class="page_con bg">
          <div class="detail_top">
            <div class="detailwrap" style="margin-bottom: 0;">
              <div class="party_tag big center">
                <!--'상세페이지에서: big'-->
                <div class="left">
                  <div
                    class="pt_tag logo"
                    :class="{ sl: detail.acntTransferYn === 'N' }"
                    style="--pt-txt-color: #fff;"
                    :style="{
                      '--pt-bg-color': $ConstCode.getCategoryById(
                        detail.categoryId
                      ).color
                    }"
                  >
                    {{ categoryNames[detail.categoryId].text }}
                  </div>
                  <div
                    class="pt_tag normal nb blue"
                    v-if="detail.acntTransferYn === 'Y'"
                  >
                    양도
                  </div>
                </div>
              </div>
              <div class="party_ttl">
                <span v-if="detail.shareYn === 'N'" class="daily"
                  >[즉시이용]</span
                >
                {{ detail.title }}
              </div>
              <div class="party_keyword" v-if="!$Util.isEmpty(searchWords)">
                <span v-for="(item, index) in searchWords" :key="index">{{
                  `#${item}`
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="detail.autoExtension === 'Y'" class="default">
            <div class="detailwrap note attention auto_background">
              <!-- 211208자동연장파티 추가 -->
              <dl>
                <dt>
                  <div class="auto_font">자동 연장 파티</div>
                </dt>
                <dd>
                  <div>
                    파티장이 최초 생성 시 설정한 이용기간과 이용금액으로 자동
                    연장되는 파티입니다. 참여 파티원은 연장해제 전까지
                    자동결제를 통해 참여가 가능합니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap">
              <dl>
                <dt>이용 기간</dt>
                <dd v-if="detail.unlimitedCheckYn === 'N'">
                  {{ $dayjs(detail.useDt).diff($dayjs(), 'd') + 1 }}일 ({{
                    $dayjs(detail.useDt)
                      .subtract(1, 'd')
                      .format('YYYY.MM.DD')
                  }}
                  까지)
                </dd>
                <dd v-else>표시 없음</dd>
              </dl>
              <dl>
                <dt>금액</dt>
                <dd v-if="detail.acntTransferYn === 'N'">
                  <b class="daily">{{
                    $Util.formatNumber2(price, { unit: '' })
                  }}</b
                  >원 (<b class="daily">{{
                    $Util.formatNumber2(detail.dailyPrice, { unit: '' })
                  }}</b
                  >원/일)
                </dd>
                <dd v-else>
                  <b class="daily">{{
                    $Util.formatNumber2(detail.dailyPrice, { unit: '' })
                  }}</b
                  >원
                </dd>
              </dl>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap">
              <div class="flex">
                <div
                  class="prof"
                  :class="[
                    {
                      off: leader.userLvNm === $ConstCode.LEVEL_TYPE['0'].name
                    },
                    {
                      vip: leader.sellerLv >= 3
                    },
                    {
                      nonebg:
                        !$Util.isEmpty(leader.profile) &&
                        leader.profile.indexOf('/null') < 0
                    }
                  ]"
                  @click="fnModalAlertSellerProfile"
                >
                  <!-- 프로필이미지 지정시 nonebg 추가 -->
                  <div class="img_area">
                    <img
                      :src="leader.profile"
                      alt=""
                      v-if="
                        !$Util.isEmpty(leader.profile) &&
                          leader.profile.indexOf('/null') < 0
                      "
                    />
                  </div>
                </div>
                <div @click="fnModalAlertSellerProfile" class="nickname">
                  {{ leader.userNm }}
                </div>
                <div @click="fnModalAlertRefundPolicy" class="right">
                  환불 정책 안내
                </div>
              </div>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap note">
              <dl>
                <dt>파티 소개 및 주의사항</dt>
                <dd>
                  <div class="intro">
                    <span>{{ detail.precautions }}</span>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="detail.autoExtension === 'Y'" class="default">
            <!-- 211212 연장결제정보 추가 -->
            <div class="detailwrap">
              <div class="auto_title">자동 연장 결제 정보</div>
              <dl>
                <dt>이용 기간</dt>
                <dd>{{ detail.usePeriod }}일</dd>
              </dl>
              <dl>
                <dt>일 판매가</dt>
                <dd>
                  {{ $Util.formatNumber2(detail.dailyPrice, { unit: '원' }) }}
                </dd>
              </dl>
              <dl>
                <dt>총 이용 금액</dt>
                <dd>
                  {{
                    $Util.formatNumber2(detail.dailyPrice * detail.usePeriod, {
                      unit: '원'
                    })
                  }}
                </dd>
              </dl>
            </div>
          </div>
          <div
            v-if="
              (detail.acntTransferYn === 'Y' ||
                detail.categoryId == '1004' ||
                detail.categoryId == '1100') &&
                [detail.reqKakao, detail.reqPhone, detail.reqEmail].some(
                  req => req == '1'
                )
            "
            class="default"
          >
            <div class="detailwrap note">
              <dl>
                <dt>파티원 요청정보</dt>
                <dd>
                  <div class="req_options">
                    <div class="option" v-if="detail.reqKakao == '1'">
                      카카오톡ID
                    </div>
                    <div class="option" v-if="detail.reqPhone == '1'">
                      휴대폰번호
                    </div>
                    <div class="option" v-if="detail.reqEmail == '1'">
                      이메일
                    </div>
                  </div>
                  <div class="color_secondary">
                    판매자가 상품전달을 위해 구매자에게 요청하는 정보입니다.
                  </div>
                  <div class="color_secondary">
                    결제단계에서 해당 정보를 입력하면 판매자에게 전달됩니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap note attention">
              <dl>
                <dt>주의하세요!</dt>
                <dd>
                  <div class="color_secondary">
                    * 위즈니 서비스를 이용하지 않고 판매자와 직접 거래하여
                    발생되는 문제에 대해서는 책임지지 않습니다.
                  </div>
                  <div>
                    * 본인 프로필만 접속해 주세요.<br />
                    - 여러 기기에서 동시 접속은 안됩니다.<br />
                    - 타인에게 무단으로 계정을 공유하는 행동은 삼가주세요.
                  </div>
                  <div>
                    * 동시접속이 아니라면 보유하고 계신 다양한 기기에서 사용
                    가능합니다!
                  </div>
                  <div>
                    * 계정 양도 구매는 구매 후 서비스가 정상 확인되면, 결제
                    승인을 눌러주십시오. 결제일로부터 3일이 지나면 자동으로 승인
                    완료 됩니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="join_area">
            <div
              v-if="
                detail.recruitNum - detail.totalMemberCnt > 0 &&
                  detail.ownerClose === 'N' &&
                  $dayjs(detail.useDt)
                    .subtract(1, 'd')
                    .diff($dayjs(), 'd') >= 0
              "
              class="inner"
            >
              <div class="top">
                <label class="checkbox st02">
                  <input
                    type="checkbox"
                    :true-value="'Y'"
                    :false-value="'N'"
                    :checked="form.partyAgree === 'Y'"
                    v-model="form.partyAgree"
                  />
                  <span class="label"
                    ><span>파티정보와 유의사항을 모두 숙지했습니다.</span></span
                  >
                </label>
              </div>
              <div class="btm">
                <div>
                  <div class="nb">
                    참여 인원 <span>{{ detail.totalMemberCnt }}</span> /
                    {{ detail.recruitNum }} 명
                  </div>
                  <button
                    class="btn"
                    v-if="detail.myPartyYn === 'Y'"
                    @click="fnEdit"
                  >
                    파티수정
                  </button>
                  <button class="btn" v-else @click="fnPage">참여신청</button>
                </div>
              </div>
            </div>
            <div class="inner" v-else>
              <div class="btm white">
                <div class="completed">해당 파티는 모집 완료됐습니다.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'party-view-index',
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
